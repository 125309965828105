<template>
  <div class="firm">
    <Navbar></Navbar>
    <div class="container">
      <div class="rowBox">
        <div id="description">
          <h1>关于安智未来</h1>
          <p>
            深圳安智未来科技有限公司成立于2024年，一直秉承“科技改变生活，用心专心创造产品”的设计、研发理念，致力于工具类应用的开发与运营。成立至今已开发多款广受用户喜爱与好评的精品应用，多款应用下载量超百万。
          </p>
        </div>
      </div>
      <div class="rowBox1">
        <div id="description1">
          <h1>关于公司</h1>
          <p>
            公司正在逐步打造丰富的产品矩阵，主要研发工具类应用，例如：拍拍识图识字、樱花小组件、起名取名字等移动应用。多年来，一直以创造各类使生活更便捷更丰富的产品为宗旨。
            我们是一支年轻有活力的创业团队，年轻、活力正是我们发展和创新的动力！
          </p>
        </div>
      </div>
    </div>

    <div class="dibu">
      <div class="dibunr">
        <div>
          <img src="@a/images/dingw_icon.png" alt />
          <p>
            2024
            <span>年</span>
          </p>
          <span>安智未来科技成立</span>
        </div>

        <div class="second">
          <img src="@a/images/icon_chanp.png" alt />
          <p>
            1个亿
            <span>+</span>
          </p>
          <span>产品得到用户海量下载</span>
        </div>
        <div>
          <img src="@a/images/icon_tuandui.png" alt />
          <p>90后</p>
          <span>年轻活力团队</span>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>
<style scoped>
.firm {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-image: url(../assets/images/Group1.png);
  background-repeat: no-repeat;
  background-size: 100vw 650px;
  background-position: top left;
}

.container {
  overflow-y: hidden;
}
.rowBox {
  width: 810px;
  overflow: hidden;
  margin: 0 auto;
  margin-right: 115px;
}
.rowBox h1 {
  font-size: 40px;
  color: #fff;
  padding-top: 110px;
  padding-left: 35%;
}
.rowBox p {
  /* width: 500px; */
  padding: 10px 0;
  color: #fff;
  font-size: 20px;
  padding-left: 35%;
}
.rowBox1 {
  padding-top: 230px;
  margin: 0 auto;
  text-align: center;
  width: 1142px;
}
.rowBox1 #description1 {
  text-align: center;
  width: 1142px;
}
.rowBox1 #description1 h1 {
  margin: 0 auto;
  text-align: center;
  font-size: 40px;
}
.rowBox1 #description1 p {
  display: block;
  width: 1142px;
  font-size: 20px;
  color: #8c8c8c;
  padding-top: 50px;
  padding-bottom: 20px;
}
.dibu {
  width: 100%;
  height: 400px;
  background-image: url(../assets/images/bg_1_1.png);
  background-repeat: no-repeat;
  background-size: 100vw 400px;
  background-position: bottom left;
}
.dibu .dibunr {
  width: 60%;
  margin: 0 auto;
  margin-top: 70px;
}
.dibu .dibunr div {
  width: 26%;
  height: 240px;
  float: left;
  border: 1px solid #fff;
  border-radius: 20px;
  text-align: center;
  background-color: #fff;
  padding-top: 10px;
}
.dibu .dibunr div img {
  height: 163px;
}
.dibu .dibunr div p {
  padding-top: 10px;
  font-size: 20px;
  color: #404040;
}
.dibu .dibunr div p span {
  font-size: 16px;
}
.dibu .dibunr div span {
  font-size: 16px;
  color: #404040;
}
.dibu .dibunr .second {
  margin: 0 83px;
}

/* 媒体查询适配 */
@media screen and (max-width: 1200px) {
  .firm {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    background-image: url(../assets/images/gybf.png);
    background-repeat: no-repeat;
    background-size: 100% 442px;
    background-position: top left;
  }
  .rowBox {
    width: 96%;
    overflow: hidden;
    margin: 0;
    /* margin-right: 115px; */
  }
  .rowBox h1 {
    font-size: 30px;
    color: #fff;
    padding-top: 30px;
    padding-left: 17px;
  }
  .rowBox p {
    /* width: 380px; */
    padding: 10px 0;
    color: #fff;
    font-size: 16px;
    padding-left: 15px;
    padding-top: 15px;
  }
  .rowBox1 #description1 h1 {
    text-align: center;
    font-size: 30px;
  }
  .rowBox1 {
    padding-top: 170px;
    text-align: left;
    width: 96%;
  }
  .rowBox1 #description1 h1 {
    margin: 0 auto;
    text-align: left;
    font-size: 30px;
    padding-left: 30px;
  }
  .rowBox1 #description1 {
    text-align: center;
    width: 100%;
  }
  .rowBox1 #description1 p {
    display: block;
    width: 100%;
    font-size: 16px;
    color: #8c8c8c;
    padding-top: 0px;
    /* padding-bottom: 20px; */
    /* padding-left: 25px; */
  }
  .dibu {
    width: 100%;
    height: 200px;
    background-image: url(../assets/images/bg_1_1.png);
    background-repeat: no-repeat;
    background-size: 100% 200px;
    background-position: bottom left;
  }
  .dibu .dibunr {
    width: 80%;
    margin: 0 auto;
    margin-top: 45px;
  }
  .dibu .dibunr div {
    width: 28%;
    height: 127px;
    float: left;
    border: 1px solid #fff;
    border-radius: 10px;
    text-align: center;
    background-color: #fff;
    padding-top: 10px;
  }
  .dibu .dibunr div img {
    height: 50px;
  }
  .dibu .dibunr div p {
    padding-top: 0px;
    font-size: 14px;
    color: #404040;
  }
  .dibu .dibunr div p span {
    font-size: 8px;
  }
  .dibu .dibunr div span {
    font-size: 8px;
    color: #404040;
  }
  .dibu .dibunr .second {
    margin: 0 15px;
    overflow-y: hidden;
    /* margin-left: 10px; */
  }
}
</style>