export default {
    productList: [
        {
            id: 3,
            img: require("@a/images/realistic.png"),
            title: "实景卫星导航",
            dec: "实景卫星导航是一款使用全景实况技术为您提供导航的软件。它能够语音播报导航路线，根据您当前的位置实时引导到达目的地。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: ''
        },
        {
            id: 1,
            img: require("@a/images/panorama.png"),
            title: "安智全景实况导航",
            dec: "全景实况导航是一款使用全景实况技术为您提供导航的软件。它能够语音播报导航路线，根据您当前的位置实时引导到达目的地。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: ''
        },
        {
            id: 5,
            img: require("@a/images/skyeyenav.png"),
            title: "智能实景导航",
            dec: "给人们带来准确的导航方位，是一款集成主流地图的聚合类地图应用，可帮助各行业有地图需求的用户，涵盖游客、司机、教师、学生、地理位置收集员等。 独有的卫星雷达功能",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: ''
        },
        {
            id: 5,
            img: require("@a/images/clip.png"),
            title: "爆剪牛",
            dec: "爆剪牛是一款智能轻量化的视频剪辑软件，提供了便捷的视频剪辑交互操作功能，以及各种智能脚本和文案方便用户创作。爆剪牛最出色的功能则是提供了创作内容模板，简单几下就能复刻爆款视频，帮助用户快速流量变现。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: ''
        },
        {
            id: 5,
            img: require("@a/images/skyeyenav.png"),
            title: "迅极卫星导航应用",
            dec: "给人们带来准确的导航方位，是一款集成主流地图的聚合类地图应用，可帮助各行业有地图需求的用户，涵盖游客、司机、教师、学生、地理位置收集员等。 独有的卫星雷达功能",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: ''
        },
    ]
}