<template>
  <div class="home">
    <navbar></navbar>
    <img class="images" src="@a/images/nr@3x.png" alt />
    <div class="container">
      <div id="slogan">
        <h1 class="h11">探微安智未来</h1>
        <h1>精益求精</h1>
        <div class="mark1">
          致 力 于 精 品 手 机 应 用
          <br />开 发 与 运 营
        </div>
        <div class="mark2">致 力 于 精 品 手 机 应 用 开 发 与 运 营</div>
      </div>
    </div>
    <sidebar></sidebar>
    <div id="index-footer">
      <p>
        <a href="https://beian.miit.gov.cn" target="_blank"
          >粤ICP备2024285942号
        </a>
      </p>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar";
import Sidebar from "@/components/Sidebar";
export default {
  components: {
    Navbar,
    Sidebar,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped lang="less">
body {
  overflow-x: hidden;
}
.home {
  height: 100vh;
  overflow: hidden;
  background-image: url(../assets/images/shouye_bg@2x.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: bottom left;
  position: relative;
}
.images {
  position: absolute;
  right: 0;
  bottom: 0;
  display: block;
  width: 78%;
}
.home .container {
  width: 100%;
  height: 784px;
  margin-top: 25px;
  padding-left: 10%;
}
#slogan {
  display: inline-block;
  /* top: 35%; */
  position: absolute;
}

h1 {
  display: inline-block;
  font-size: 60px;
  color: #333;
  font-family: "PingFang SC";
  color: #404040;
}

.h11 {
  margin-right: 50px;
}
.mark2 {
  color: #404040;
  font-size: 30px;
  /* text-align: center; */
  /* width: 700px; */
  height: 56px;
  line-height: 56px;
  text-align: center;
  font-weight: 200;
  display: block;
}
.mark1 {
  display: none;
}
#index-footer {
  text-align: center;
  z-index: 1000;
  position: absolute;
  width: 100%;
  bottom: 24px;
  color: #c0c0c0;
}

#index-footer p {
  font-size: 10px;
  margin: 0;
  color: #fff;
}

#index-footer a {
  color: #fff;
}

/* 媒体查询适配 */
@media screen and (max-width: 1200px) {
  .home {
    height: 100vh;
    overflow: hidden;
    background-image: url(../assets/images/shouye_bg@2x.png);
    background-repeat: no-repeat;
    background-size: 100% 400px;
    background-position: bottom left;
    position: relative;
  }

  .home .container {
    width: 100vw;
    height: 784px;
    margin-top: 100px;
    padding-left: 15px;
  }
  .h11 {
    margin-right: 10px;
  }
  h1 {
    display: block;
    font-size: 40px;
    color: #333;
    font-weight: 500;
    font-family: "PingFang SC";
    color: #404040;
  }
  .mark1 {
    color: #404040;
    font-size: 20px;
    /* text-align: center; */
    /* width: 700px; */
    height: 56px;
    line-height: 28px;
    text-align: left;
    font-weight: 200;
    display: block;
  }
  .mark2 {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .images {
    // bottom: 46px;
    display: block;
    width: 100%;
    min-width: 400px;
  }
  #slogan {
    left: 30px;
  }
}
</style>
